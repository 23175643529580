/*  ==========================================================================
    MOBILE HEADER V3
    ========================================================================== */

@mixin mobile-header--v3() {
    @include mobile-header--v1();
    background: linear-gradient(rgba($clr__black, 0.4), transparent);
    border-bottom-width: 0;
    // lang menu
    .bn-lang-menu {
        &.bn-lang-menu--above {
            ul {
                color: $clr__white;
                li {
                    a {
                        color: $clr__white;
                        &:visited {
                            color: $clr__white;
                        }
                        &:hover {
                            color: $clr__ci--primary;
                        }
                    }
                }
            }
        }
    }
    .bn-control-bar--open {
        .bn-tel {
            a {
                white-space: nowrap;
            }
            .bn-icon {
                display: none;
            }
        }
    }
    // burger menu
    .bn-burger-menu {
        color: $clr__white;
        &:hover {
            color: $clr__ci--primary;
        }
    }
    // no vista
    &.bn-mobile-header--no-vista {
        &:not(.bn-mobile-header--minimized) {
            background: none;
            background-color: #fff;
            border-bottom-width: 1px;
            border-color: $clr__white;
            // burger menu
            .bn-burger-menu {
                &:hover {
                    color: $clr__ci--secondary;
                }
            }
        }
    }
    // minimized
    &.bn-mobile-header--minimized {
        background: $clr__ci--primary;
        border-bottom-width: 1px;
    }
}
