/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.bn-footer {
    position: relative;
    visibility: visible !important;
    z-index: $unit__zi--footer;
    // type 1
    &.bn-footer--v1 {
        @include footer--v1();
    }
    // type 2
    &.bn-footer--v2 {
        @include footer--v2();
    }
    // type 3
    &.bn-footer--v3 {
        @include footer--v3();
    }
    // top references
    .bn-top-references {
        &.bn-top-references--mobile {
            bottom: 40px;
            display: none;
            position: fixed;
            right: 40px;
            z-index: $unit__zi--footer-top-references;
            .bn-top-references__item {
                background-color: $clr__ci--primary;
                border: 1px solid $clr__white;
                border-radius: 100%;
                color: $clr__typo--secondary;
                cursor: pointer;
                display: none;
                font-size: 2.5em;
                height: 50px;
                line-height: 48px;
                margin-top: 10px;
                text-align: center;
                width: 50px;
                .bn-icon {
                    &.bn-icon--minus {
                        display: none;
                    }
                }
                &.bn-top-references__item--visible {
                    display: block;
                }
                &.bn-top-references__item--trigger {
                    display: block;
                    &.bn-top-references__item--act {
                        .bn-icon {
                            &.bn-icon--plus {
                                display: none;
                            }
                            &.bn-icon--minus {
                                display: inline-block;
                            }
                        }
                    }
                }
                &:first-child {
                    margin-top: 0;
                }
                &:hover {
                    background-color: $clr__ci--secondary;
                }
            }
        }
    }
    // print address
    .bn-address-print {
        display: none;
    }

    #bn_newsletter_v2 .bn-form__button {
        border-color: $clr__typo--secondary !important;
        background: transparent !important;
        color: $clr__typo--secondary !important;
        &:hover {
            border-color: $clr__typo--secondary !important;
            background: $clr__typo--secondary !important;
            color: $clr__ci--primary !important;
        }
    }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// 370px max height
@media #{$mq__max-h--370} {
    .bn-footer {
        // top references
        .bn-top-references {
            display: none !important;
        }
    }
}

// tablet
@media #{$mq__tablet} {
    .bn-footer {
        // top references
        .bn-top-references {
            &.bn-top-references--mobile {
                &.bn-top-references--visible {
                    display: block;
                }
            }
        }
    }
}

// phablet
@media #{$mq__phablet} {
    .bn-footer {
        // top references
        .bn-top-references {
            &.bn-top-references--mobile {
                bottom: 20px;
                right: 20px;
            }
        }
    }
}
