/*  ==========================================================================
    HEADER V3
    ========================================================================== */

@mixin header--v3() {
    left: 0;
    min-height: $unit__hgt--header-v3;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    // controls
    .bn-controls {
        color: $clr__white;
        .bn-controls__left,
        .bn-controls__center,
        .bn-controls__right {
            padding: 30px 0 40px 0;
        }
        .bn-controls__left {
            z-index: 20;
        }
        .bn-controls__center {
            text-align: center;
            max-width: 315px;
            background: $clr__typo--secondary;
            padding: 20px 55px 15px;
        }
        .bn-controls__right {
            font-size: 2em;
            text-align: right;
            a {
                color: $clr__white;
                &:visited {
                    color: $clr__white;
                }
                &:hover {
                    color: $clr__ci--primary;
                }
            }
        }
    }
    // burger menu
    .bn-burger-menu {
        cursor: pointer;
        display: inline-block;
        pointer-events: initial;
        margin-top: 8px;
        .bn-icon {
            font-size: 5em;
        }
        &.bn-sidebar-trigger--act {
            display: none;
        }
        &:hover {
            color: $clr__ci--primary;
        }
    }
    // sidebar
    .bn-sidebar {
        @include header--sidebar();
        pointer-events: initial;
        color: #fff;

        .bn-tel {
            a {
                white-space: nowrap;
            }
            .bn-icon {
                display: none;
            }
        }
    }
    // main logo
    .bn-main-logo {
        &.bn-main-logo--above {
            display: inline-block;
            pointer-events: initial;
            .bn-main-logo__image {
                img {
                    @include img--rendering-crisp-edges();
                    max-width: 240px;
                }
                &.bn-main-logo__image--big {
                    img {
                        width: 205px;
                        height: auto;
                    }
                }
                &.bn-main-logo__image--small {
                    display: none;
                }
                &.bn-main-logo__image--mobile {
                    display: none;
                }
            }
        }
    }

    //add arrow to menu with submenu
    .bn-main-menu__item--has-children .bn-main-menu__title::after {
        content: '\e902';
        font-family: 'bn-icons' !important;
        font-size: 0.8em;
        margin-left: 5px;
    }

    // tel
    .bn-email,
    .bn-tel {
        &.bn-email--above,
        &.bn-tel--above {
            display: inline-block;
            height: 36px;
            line-height: 36px;
            pointer-events: initial;
            white-space: nowrap;
            margin-left: 20px;
            .bn-icon {
                font-size: 2em;
                &:before {
                    vertical-align: middle;
                }
            }
            .break {
                display: none;
            }
            @media #{$mq__desktop--header-break} {
                display: block;
            }
        }
    }
    // no vista
    &.bn-header--no-vista {
        &:not(.bn-header--minimized) {
            // main logo
            .bn-main-logo {
                &.bn-main-logo--above {
                    .bn-main-logo__image {
                        img {
                            height: 80px;
                            width: auto;
                        }
                    }
                }
            }
        }
        .bn-controls__center {
            display: none;
        }
    }
    // minimized
    &.bn-header--minimized {
        background: linear-gradient(rgba($clr__black, 0.4), transparent);
        // controls
        .bn-controls {
            .bn-controls__center,
            .bn-controls__right {
                display: none;
            }
        }
    }

    /*
   * RESPONSIVE BEHAVIOR
   */

    // full screen on
    @media #{$mq__full-screen--on} {
        position: fixed;
    }
    // laptop
    @media #{$mq__laptop} {
        // controls
        // .bn-controls {
        //     .bn-controls__center {
        //         max-width: 200px;
        //     }
        // }
        // main logo
        .bn-main-logo {
            &.bn-main-logo--above {
                .bn-main-logo__image {
                    img {
                        max-width: 160px;
                    }
                }
            }
        }
    }
}
